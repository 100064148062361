import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

i18n
    .use(initReactI18next)
    .use(LanguageDetector)
    .init({
        resources: {
            en: {
                translation: {
                    "download our app": "Download our app",
                    "get the app": "Get the app",
                    "download venderlane app": "Download VenderLane app",
                    "we are available on ios and android": "we are available on ios and android",
                    "two million products twenty cat": "Get real time access to top suppliers and products",
                    "top brands and vendors": "Start your B2B journey now",
                    "view all": "View All",
                    "about us": "About us",
                    "about vender lane": "Our Team",
                    "gift cards": " Gift Cards",
                    "careers": "Careers",
                    "sell on venderlane": "Sell On Vender Lane",
                    "investor relations": "Investor Relations",
                    "advertise with us": "Advertise With Us",
                    "locations": "Locations",
                    "customer service": "Customer Service",
                    "my orders": "My Orders",
                    "track my orders": "Track My Orders",
                    "accessibility statement": "Accessibility Statement",
                    "return policy": "Return Policy",
                    "help center": "Help Center",
                    "contac us": "Contact Us",
                    "quick help": "Chat With Us",
                    "call us": "Call Us",
                    "privacy policy": "Privacy Policy",
                    "shopping assistance": "Shopping Assistance",
                    "app title": "Venderlane",
                    "languages": "Languages",
                    "sell on": "Sell on VL",
                    "helpdesk": "HelpDesk",
                    "Sign in/Sign up": "Sign in/Sign up",
                    "login with otp": "Login with OTP",
                    "Login with password": "Login with password",
                    "current password": "Current Password",
                    "new Password": "New Password",
                    "update password": "Update password",
                    "enter current password": "Please Enter the Current passowrd",
                    "enter new password": "Please Enter the new Password",
                    "Please enter the mobile number": "Please enter the mobile number",
                    "Please enter the valid mobile number": "Please enter the valid mobile number",
                    "mobile number is required": "Mobile number is Required*",
                    "Password is Required": "Password is Required",
                    "must contain": "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
                    "send otp": "Send OTP",
                    "minimum number": "Minimum 9 number should be there",
                    "otp require": "OTP is Required",
                    "verify otp": "Verify OTP",
                    "login": "Login",
                    "dont have account": "Don't have account?",
                    "create new account": "Create new account",
                    "register with us": "Register with us",
                    "first name": "First Name",
                    "last name": "Last Name",
                    "email": "Email",
                    "mobile number": "Mobile Number",
                    "cr number": "CR Number",
                    "company name": "Company Name",
                    "password": "Password",
                    "dob": "Date of Birth (Optional)",
                    "confirm password": "Confirm Password",
                    "submit": "Submit",
                    "already have account": "Already have an account",
                    "login here": "Login Here",
                    "Please enter the first name": "Please enter the first name",
                    "Please enter the last name": "Please enter the last name",
                    "Please enter the email id": "Please enter the email id",
                    "Please enter the password": "Please enter the password",
                    "Please enter the confirm password": "Please enter the confirm password",
                    "Password not matched": "Password not matched",
                    "Please enter the valid email": "Please enter the valid email",
                    "first name is required": "first name is required*",
                    "last name is required": "Last name is Required*",
                    "mail is required": "Email ID is Required*",
                    "cr number is required": "cr number is required*",
                    "company name is required": "company name is required*",
                    "password is required": "Password is Required*",
                    "department is required": "Industry is Required*",
                    "confirm passowrd is required": "Confirm Password is Required*",
                    "phone number is not valid": "Phone number is not valid",
                    "enter a Valid mail address": "Enter a Valid mail address",
                    "invalid CR number": "Invalid CR number",
                    "12 digits cr": "Minimum of 12 digits Required*",
                    "passwords are not matching": "Both password and confirm password are not matching",
                    "current Password is Required": "Current Password is Required",
                    "new Password is Required": "New Password is Required",
                    "minimum otp": "Minimum 6 number should be there",
                    "max num": "Maximum of 10 number you can use",
                    "max num cr": "Maximum of 15 number you can use",
                    "max num_fl": "Maximum of 15 number you can use",
                    "add to cart": "ADD TO CART",
                    "out of stock": "Out of stock",
                    "grab offers": "Grab offers",
                    "department": "Industry",
                    "select department": "Select Industry",
                    "results not found": "Results are not found",
                    "account": "Account",
                    "chats": "Chats",
                    "wishlist": "Wishlist",
                    "search": "Search on VENDERLANE",
                    "top products of the day": "Top products of the day",
                    "Explore other departments": "Explore other Industry",
                    "sort": "Sort",
                    "filters": "Filter by",
                    "new": "Newest First",
                    "old": "Oldest First",
                    "hightolow": "Price High to Low",
                    "lowtohigh": "Price Low to High",
                    "listings": "Listings",
                    "update cart": "Update Cart",
                    "add to wishlist": "Add to wishlist",
                    "remove from WishList": "Remove from WishList",
                    "product details": "Product Details",
                    "similar products": "Similar products",
                    "minimum order quantity": "Minimum order quantity",
                    "select quantity": "Select Quantity",
                    "add item": "Add Item",
                    "extra": "Extra",
                    "off": "Off",
                    "my account": "My Account",
                    "hello": "Hello",
                    "orders": "ORDERS",
                    "all orders": "All Orders",
                    "orders chat": "Orders Chats",
                    "my chats": "My Chats",
                    "my profile": "My Profile",
                    "saved address": "Saved Addresses",
                    "legal": "Legal",
                    "terms & conditions": "Terms & Conditions",
                    "logout": "Logout",
                    "are you sure": "Are you sure ?",
                    "no": "No",
                    "yes": "Yes",
                    "logout from venderlane": "Logout from the Venderlane account",
                    "You want to Delete the address!": "You want to Delete the address!",
                    "order id": "Order ID",
                    "total price": "Total Price",
                    "order status": "Order Status",
                    "quatation from venderlane": "Quotation from Vender Lane",
                    "items in this order": "Items in this order",
                    "delivery address": "Delivery Address",
                    "total order price": "Total order price",
                    "summary": "Summary",
                    "subtotal": "Subtotal:",
                    "vat 18%": "Vat",
                    "discount": "Discount",
                    "total": "Total",
                    "chat with vender": "Chat with vendor",
                    "enter your message": "Enter your message",
                    "send": "Send",
                    "you have": "You have",
                    "new message from Vendor": "new message from Vendor",
                    "edit profile": "Edit Profile",
                    "registred mobile number": "Registered mobile number",
                    "change password": "Change Password",
                    "update": "Update",
                    "update user details": "Update user Details",
                    "add new address": "Add New Address",
                    "edit": "Edit",
                    "delete": "Delete",
                    "Make this address as default address": "Make this address as default address",
                    "landmark": "Landmark(Optional)",
                    "pincode": "Pincode",
                    "search address": "Search Address",
                    "pincode minimum": "Minimum of 5 numbers should be there",
                    "pincode is required": "Pincode is Required*",
                    "add": "Add",
                    "the address": "the Address",
                    "remove item": "Remove Item",
                    "move to wishlist": "Move to wishlist",
                    "items": "Items",
                    "my cart": "My Cart",
                    "total quantity count": "Total Quantity Count",
                    "total est price": "Total Est.Price",
                    "request for quotation": "Request for Quotation",
                    "submit order": "Submit Order",
                    "address is required": "Address is Required*",
                    "order request submitted": "Your request has been submitted successfully.",
                    "request underprocess": "Your registration request is under process, will get back to you shortly!",
                    "view orderlist": "View Orderlist",
                    "move to cart": "Move to Cart",
                    "cart empty": "Your cart is Empty...!",
                    "continue shopping": "Continue Shopping",
                    "empty wishlist": "Your wishlist is Empty...!",
                    "details are not availanble": "Details are not available...!",
                    "data is not there": "The data are not there for the Selected industry",
                    "select other dept": "Select Other Industry from above",
                    "shop by category": "Shop by category",
                    "combo offer deals": "Combos, offers & deals",
                    "Flat 10% of for new user": "10% discount on total billed amount for new users. Use code - NEWONVLANE",
                    "newonvlane": "NEWONVLANE",
                    "clearance sale": "Clearance Sale",
                    "Quantity should not less than minimum quantity": "Quantity should not be less than the minimum required",
                    "vendor business listing": "Vendor Business Listing",
                    "tax certificate": "Tax Certificate",
                    "add website linr here (optional)": "Add website link here (Optional)",
                    "Listing Information": "Listing Information",
                    "Business Location": "Business Location",
                    "Building": "Building",
                    "Street": "Street",
                    "Area": "Area",
                    "City": "City",
                    "State": "State",
                    "Country": "Country",
                    "landmarkTwo": "علامة مميزة",
                    "business name": "Business Name",
                    "Legal business name": "Legal business name",
                    "Business category": "Business category",
                    "Business Information": "Business Information",
                    "Business Sub Cateories": "Business Sub Categories",
                    "Business Cateories": "Business Categories",
                    "Business Description": "Business Description",
                    "Business Timing": "Business Timing",
                    "Sun": "Sun",
                    "Mon": "Mon",
                    "Tue": "Tue",
                    "Wed": "Wed",
                    "Thu": "Thu",
                    "Fri": "Fri",
                    "Sat": "Sat",
                    "open by": "Open By",
                    "close by": "Close By",
                    "open": "Open",
                    "closed": "Closed",
                    "Upload Cover Photo": "Upload Cover Photo",
                    "Upload Profile Photo": "Upload Profile Photo",
                    "Upload Photo": "Upload Photo",
                    "manager name": "Manager Name",
                    "business mail address": "Business Mail Address",
                    "business contact number": "Business Contact Number",
                    "Upload Product Photo": "Upload Product Photo",
                    "landline number": "Landline Number",
                    "webisite": "Website",
                    "stores": "Stores",
                    "accept order": "Accept Order",
                    "chat box": "Chat Box",
                    "Business Name is Required*": "Business Name is Required*",
                    "Manager Name is Required*": "Manager Name is Required*",
                    "Business mail is Required*": "Business mail is Required*",
                    "City is Required*": "City is Required*",
                    "Category is Required*": "Category is Required*",
                    "Description is Required*": "Description is Required*",
                    "Business days are Required*": "Business days are Required*",
                    "Required*": "Required*",
                    "Landline Number is Required*": "Landline Number is Required*",
                    "Tax file is Required*": "Tax file is Required*",
                    "CR file is Required*": "CR file is Required*",
                    "listing information": "Listing Information",
                    "product categories": "Product Categories",
                    "Descriptions": "Descriptions",
                    "photos": "Photos",
                    "select city": "Select City",
                    "something went wrong": " Oops, Something went wrong",
                    "data not available": "The data you are looking  for is not available",
                    "go back": "Go Back",
                    "back": "Back",
                    "depart_ment": "Department",
                    "Seller Registration": "Seller Registration",
                    "upload business logo": "Upload Business Logo",
                    "Other service registration": "Other service registration",
                    "step two": "Step Two",
                    "upload business photos": "Upload Business Photos",
                    "categories": "Categories",
                    "Cities": "Cities",
                    "service provider list": "Service Provider List",
                    "business city": "Business City",
                    "business address": "Business Address",
                    "business category": "Business Category",
                    "address": "Address",
                    "other services": "Other Services",
                    "service provider information": "Service Provider Information",
                    "Working Hours": "Working Hours",
                    "serivece categories": "Service Categories",
                    "Directions": "Directions",
                    "business documents": "Business Documents",
                    "go home": "Go Home",
                    "serivice provider": "Service Providers",
                    "select category": "Select Category",
                    "select service provider": "Select Service Providers",
                    "Please_Select_address": "Please Select address...",
                    "service providers": "Other Services ",
                    "contact mail address": "Contact mail address",
                    "select item quantity": "Select item quantity",
                    "closses": "Closes",
                    "opens": "Opens",
                    "service provider registration": "Service Provider Registration",
                    "get directions": "Get Directions",
                    "select services": "Select Services",
                    "copyright": "Vender Lane |Copyright@2023, All Rights Reserved | Privacy Policy",
                    "copyright1": "Copyright@2024 Venderlane.com | All Rights Reserved",
                    "seller business listing": "Seller Business Listing",
                    "vender information": "Vendor Information",
                    "faq": "FAQ",
                    "register as a seller": "Register as a seller",
                    "register as a buyer": "Register as a buyer",
                    "register as a service provider": "Register as a service provider",
                    "our team": "Our Team",
                    "our story": "Our Story",
                    "reject order": "Reject Order",
                    "estimated": "Est",
                    "faqs": "Frequently Asked Questions",
                    "vterms&condition": "VenderLane Terms and Conditions",
                    "terms sub heading": "General Terms and Conditions Governing Vender Lane’s services for Business Entities and Entrepreneurs.",
                    "vender privacy": "VenderLane Privacy Policy﻿",
                    "loading": "Loading.....",
                    "you have not ordered anything": "You have not ordered anything",
                    "Continue shoping": "Continue shoping",
                    "no addresses": "There are no saved addresses",
                    "add address": "Add New Address",
                    "forgot password": "Forgot Password",
                    "shipping charge": "Shipping Charge",
                    "do you need shipping": "Do You Need Shipping ?",
                    "need shipping": "Please select shipping Needed or Not.",
                    "register": "Register",
                    "menu": "Menu",
                    "items not found": "Items are not found",
                    "confirm and submit": "Confirm and Submit order",
                    "select delivery address": "Select delivery address",
                    "inquiry and support": "For inquiry and Support contact with",
                    "rate yor experience": "Rate your experience",
                    "rate us": "Rate Us",
                    "submit feedback": "Submit Feedback",
                    "enter comments": "Enter comments",
                    "rate vendor": "Rate Vendor",
                    "pieces": "PIECES",
                    "per pieces": "Per One Quantity",
                    "no reviews yet": "No reviews yet",
                    "Rating and Review": "Ratings and Reviews",
                    "service questions": "Question related to the service provider",
                    "more than zero": "Quantity Should not be zero",
                    "not include decimal": "Quantity should not include decimal point",
                    "enter your phone number start with 5xxxxxxxx": "Enter your phone number start with 5xxxxxxxx",
                    "tax invoice": "Performa Invoice",
                    "Uploading Document": "Uploading Document",
                    "download file": "Download File",
                    "No Ratings Yet": "No Ratings Yet",
                    "most ordered": "Most Ordered",
                    "stock available": "Stock Available",
                    "most rated": "Most Rated",
                    "price range": "Price Range",
                    "apply filters": "Apply Filters",
                    "in SAR": "in",
                    "Categories": "Categories",
                    "Variations": "Variations",
                    "global ratings": "Global Ratings",
                    "customer review": "Customer reviews",
                    "out of 5": "out of 5",
                    "star": "star",
                    "clear filters": "Clear Filters",
                    "brands": "Brand",
                    "most viewed": "Most Viewed",
                    "Customer Reviews": "Customer Reviews",
                    "& Higher": "& Higher",
                    "Sub Categories": "Sub Categories",
                    "locate": "Locate",
                    "documents": "Documents",
                    "doc": "Doc",
                    "view pdf": "View PDF",
                    "search for brands": "Search For Brands",
                    "search for Sub Categories": "Search For Sub Categories",
                    "search for city": "Search For City",
                    "show more": "Show More",
                    "show less": "Show Less",
                    "from": "From",
                    "to": "To",
                    "To Price should not be lesser than From Price": "To Price should not be lesser than From Price",
                    "document view": "Document View",
                    "gallery view": "Gallery View",
                    "Best seller in": "Best seller in",
                    "Discounted, Promo  & combo products": "Discounted, Promo  & combo products",
                    "Clearance sale products": "Clearance sale products",
                    "Notifications": "Notifications",
                    "Rewards": "Rewards",
                    "talk to us": "Talk to us",
                    "Become a service provider": "Become a service provider",
                    "Become a seller": "Become a seller",
                    "SELL ON VENDERLANE": "SELL ON VENDERLANE",
                    "notify me": "Notify Me",
                    "notofied": "Notified",
                    "standard products": "Standard Products",
                    "follow us on social": "Follow us on social",
                    "access on the go, download our app": "Access on the go, Download our app",
                    "copyright2": "Copyright@2024 Venderlane.com | All Rights Reserved",
                    "directremittance": "Direct remittance to supplier ",
                    "weacceptpaymentmethods": "we accept all major payments methods :",
                    "departments": "INDUSTRY",
                    "cart": "Cart",
                    "all departments": "ALL INDUSTRY",
                    "information": "Information",
                    "blogs": "Blogs",
                    "All Blogs": "All Blogs",
                    "Related blogs": "Related Blogs",
                    "IBAN Number": "IBAN",
                    "Accept": "Accept",
                    "Cancel": "Cancel",
                    "Agreement and Conditions": "Agreement and Conditions",
                    "Please accept the Agreement and Conditions": "Please accept the Agreement and Conditions",
                    "showing": "Showing",
                    "products": "Products",
                    "productof": "Products of",
                    "I have read and agreed with the Agreement and conditions.": "I have read and agreed with the Agreement and conditions.",
                    "IBAN Number is not valid": "IBAN Number is not valid",
                    "Minimum 22 digits Required": "Minimum 22 digits Required",
                    "Minimum 4 digits Required": "Minimum 4 digits Required",
                    "Page Not Found": "Page Not Found",
                    "Sorry, the page you are looking for could not be found.": "Sorry, the page you are looking for could not be found.",
                    "order details": "Order Details",
                    "back to order detilas": "Back to Order Details",
                    "Your payment is Successful": "Your payment is Successful",
                    "Your payment transaction number": "Your payment transaction number",
                    "Your payment is failed": "Your payment is failed",
                    "To Bank Account": "Bank Transfer",
                    "Payment Gateway": "Payment Gateway",
                    "Payment Through": "Payment Through",
                    "IBAN is Not Availabele": "IBAN is Not Availabele",
                    "Payment Transaction Details": "Payment Transaction Details",
                    "Transaction ID": "Transaction ID",
                    "Transaction Date": "Transaction Date",
                    "Negative Values are not allowed": "Negative Values are not allowed",
                    "View less": "View less",
                    "View more": "View more",
                    "Reviews": "Reviews",
                    "Available": "Available",
                    "Variant": "Variant",
                    "Price": "Price",
                    "Range": "Range",
                    "Select Variation": "Select Variation",
                    "No Options": "No Options",
                    "HomePage": "Homepage",
                    "Service Provider": "Service Provider",
                    "We're Rated": "We're Rated",
                    "Globally": "Globally",
                    "Total Amount": "Total Amount",
                    "Order date": "Order date",
                    "Delivery date": "Delivery date",
                    'Total Items': "Total Items",
                    "Total Qty": "Total Quantity",
                    "Update and Save": "Update and Save",
                    "Search Order": "Search Orders",
                    "last month": "Last Month",
                    "last 3 month": "Last 3 Month",
                    "last 6 month": "Last 6 Month",
                    "last year": "Last Year",
                    "Product not available in selected quantity": "The product is not available in the specified quantity.",
                    "One Time Password": "OTP",
                    "Maximum of 15 number you can use": "Maximum of 15 number you can use",
                    "return and exchange policy": "Return and Exchange Policy.",
                    "arabic": "arabic",
                    "english": "English",
                    "Business Type": "Business Type",
                    "Freelance": "Freelance",
                    "Commercial entity": "Commercial entity",
                    "freelance license number": "Freelance license number",
                    "This field is required": "This field is required",
                    "Delivery Company": "Delivery Company",
                    "Delivery Status": "Delivery Status",
                    "Tracking ID": "Tracking ID",
                    "Track Now": "Track Now",
                    "Track Shippiment": "Track Shippiment",
                    "Quantity can not be less than minimum quantity": "Quantity can not be less than minimum quantity",
                    "Input must start with 10": "Number must start with 20 and be between 10 and 15 digits long",
                    "PostCode": "PostCode",
                    "Contact Name": "Contact Name",
                    "Brand Name": "Brand Name",
                    "Email": "Email",
                    "Name": "Name",
                    "OTO Details": "OTO Details",
                    "Ware-House Details": "Ware-House Details",
                    "Ware Houses": "Ware Houses",
                    "Add WareHouse": "Add WareHouse",
                    "Code": "Code",
                    "Contact Person": "Contact Person",
                    "Contact Mail": "Contact Mail",
                    "Contact Number": "Contact Number",
                    "Serving Radius in KM": "Serving Radius in KM",
                    "Warehouse Location": "Warehouse Location",
                    "Resend OTP in": "Resend OTP in",
                    "Seconds": "Seconds",
                    "Date of birth cannot be in the future": "Date of birth cannot be in the future",
                    "Looks like you haven't made any purchases yet": "Looks like you haven't made any purchases yet",
                    "The available Quantity of Product is": "The available Quantity of Product is",
                    "Available Quantity": "Available Quantity",
                    "Payment Mode": "Payment Mode",
                    "Bank Method": "Bank Method",
                    "Roll over image to zoom in": "Roll over image to zoom in",
                    "update mobile number": "Update Mobile Number",
                    "change mobile number": "Change Mobile Number",
                    "Click to open Expanded View": "Click to open Expanded View",
                    "No Orders Found": "No Orders Found",
                    "Close Add Address": "Close Add Address",
                    "Have a coupon code?": "Have a coupon code?",
                    "Applied": "Applied",
                    "Remove": "Remove",
                    "Removed": "Removed",
                    "Enter coupon code": "Enter coupon code",
                    "Apply": "Apply",
                    "shipping discount": "Shipping Discount",
                    "This is not a valid number": "This is not a valid number",
                    "Free-Lance Certificate": "Free-Lance Certificate",
                    "cr certificate": "cr certificate",
                    "venderlane_rights": "With all rights reserved for Venderlane for information technology.",
                    "Prices include added tax": "Price Includes Value Added Tax",
                    "Please enter correct CR number": "Please enter correct CR number",
                    "Please enter correct FL number": "Please enter correct FL number",
                    "Browse same vendor products": "Browse Same Vendor Products",
                    "Sold By": "Sold By",
                    "Minimum Order": "Minimum Order",
                    "Add items worth": "Add items worth",
                    "or more to place the order": "or more to place the order",
                    "You’re all set. Let’s Place the order": "You’re all set. Let’s Place the order",
                    "One/More vendor's total price in your cart is less than minimum order price": "One/More vendor's total price in your cart is less than minimum order price",
                    "One/More vendor's total price in your cart is less than minimum quantity": "One/More vendor's total price in your cart is less than minimum quantity",
                    " Please select an item": " Please select an item",
                    "SAR": "SAR",
                    "Venderlane saudi made platform": "Venderlane Saudi platform that brings together wholesale traders empowered by innovative technical and logistical solutions.",
                    "Upload Bank Transfer Document": "Upload Bank Transfer Document",
                    "Payment Details": "Payment Details",
                    "Minimum 10 digits Required": "Minimum 10 digits Required",
                    "Minimum 8 digits Required": "Minimum 8 digits Required",
                    "Please select an item": "Please select an item",
                    "Write Here": "Write Here",
                    "Click Here": "Click Here to View Document",
                    "Extra Discount": "Extra Discount",
                    "Extra Shipping Discount": "Extra Shipping Discount",
                    "How to Buy?": "How to Buy?",
                    "quantity in your cart is less than minimum quantity": "quantity in your cart is less than minimum quantity",
                    "Regions": "Regions",
                    "Village": "Village",
                    "Delivery Address": "Delivery Address",
                    "Change Address": "Change",
                    "Select the Shipping Partner": "Select the Shipping Partner",
                    "Calulating the Shipping Cost...": "Calulating the Shipping Cost...",
                    "The Total Shipping Cost": "The Total Shipping Cost",
                    "Shahen": "Shahen",
                    "Falcon": "Falcon",
                    "Select Shipping Partner": 'Select Shipping Partner',
                    "Estimated Shipping Price": "Estimated Shipping Price",
                    "Shipping charges will be applicable in the quotation.": "Shipping charges will be applicable in the quotation.",
                    "For Heavy Qauntities": "For Heavy Quantities",
                    "Ware House Name": "Ware House Name",
                    "SHIPPING DETAILS": "SHIPPING DETAILS",
                    "ORDER DETAILS": "ORDER DETAILS",
                    "STATUS": "STATUS",
                    "ORDER ID": "ORDER ID",
                    "VL QUOTATION": "VL QUOTATION",
                    "TAX INVOICES": "TAX INVOICES",
                    "TRACKING YOUR ORDER": "TRACKING YOUR ORDER",
                    "PAYMENT DETAILS": "PAYMENT DETAILS",
                    "BANK DETAILS": "BANK DETAILS",
                    "YOUR PAYMENT METHOD": "YOUR PAYMENT METHOD",
                    "TRANSACTION DATE": "TRANSACTION DATE",
                    "TRANSACTION ID": "TRANSACTION ID",
                    "Shipment Processed by": "Shipment Processed by",
                    "On Delivery by": "On Delivery by",
                    "In Transit by": "In Transit by",
                    "Company": "Company",
                    "Date": "Date",
                    "Time": "Time",
                    "TRACKING DETAILS": "TRACKING DETAILS",
                    "Weight": "Weight",
                    "Shipping Partner": "Shipping Partner",
                    "User Name": "User Name",
                    "Title": "Title",
                    "Images": "Images",
                    "Enquiry Request": "Enquiry Request",
                    "Description": "Description",
                    "Please enter product description": "Please enter product description",
                    "Please enter the title":"Please enter the title",
                    "Please enter your name":"Please enter your name",
                    "Didn't find what you are looking for?":"Didn't find what you are looking for?",
                    "Provide prouct info":"Provide the description of the product and we will do our best to source it to you.",
                    "Full Name":"Full Name",
                    "VAT Number":"VAT Number",
                    "National Address":"National Address",
                },
            },
            ar: {
                translation: {
                    "National Address":"العنوان الوطنى",
                    "VAT Number":"رقم ضريبة القيمة المضافة",
                    "Full Name":"الاسم الكامل",
                    "Provide prouct info":"قم بوصف المنتج وسنبدل قصارى جهدنا لتوفيره لك",
                    "Didn't find what you are looking for?":"لم تجد ماتبحث عنه",
                    "Please enter your name":"الرجاء إدخال اسمك",
                    "Please enter the title":"الرجاء إدخال العنوان",
                    "Please enter product description": "الرجاء إدخال وصف المنتج",
                    "Description": "الوصف ",
                    "Enquiry Request": "طلب استعلام",
                    "Images": "صور",
                    "Title": "اللقب",
                    "User Name": "اسم المستخدم",
                    "Shipping Partner": "شريك الشحن",
                    "Weight": "وزن",
                    "TRACKING DETAILS": "تفاصيل التتبع",
                    "Time": "الزمن",
                    "Date": "تاريخ",
                    "Company": "شركة",
                    "In Transit by": "في العبور بواسطة",
                    "On Delivery by": "عند التسليم بواسطة",
                    "Shipment Processed by": "الشحنة التي تمت معالجتها بواسطة",
                    "TRANSACTION ID": "معرف المعاملة",
                    "TRANSACTION DATE": "تاريخ المعاملة",
                    "YOUR PAYMENT METHOD": "طريقة الدفع الخاصة بك",
                    "BANK DETAILS": "تفاصيل البنك",
                    "PAYMENT DETAILS": "تفاصيل الدفع",
                    "TRACKING YOUR ORDER": "تتبع طلبك",
                    "TAX INVOICES": "فواتير ضريبية",
                    "VL QUOTATION": "عرض السعر VL",
                    "ORDER ID": "معرف الطلب",
                    "STATUS": "حالة",
                    "ORDER DETAILS": "تفاصيل الطلب",
                    "SHIPPING DETAILS": "تفاصيل الشحن",
                    "Ware House Name": "اسم المستودع",
                    "For Heavy Qauntities": "للكميات الثقيله",
                    "Shipping charges will be applicable in the quotation": "رسوم الشحن سوف تظهر في عرض السعر",
                    "Estimated Shipping Price": "سعر الشحن التقديري",
                    "Select Shipping Partner": "اختر شريك الشحن",
                    "Falcon": "فالكون",
                    "Shahen": "منصة شحن",
                    "The Total Shipping Cost": "إجمالي تكلفة الشحن",
                    "Calulating the Shipping Cost...": "جارٍ حساب تكلفة الشحن...",
                    "Select the Shipping Partner": "حدد شريك الشحن",
                    "Change Address": "تعديل",
                    "Delivery Address": "عنوان التسليم",
                    "Village": "قرية",
                    "Regions": "المناطق",
                    "quantity in your cart is less than minimum quantity": "الكمية الموجودة في سلة التسوق الخاصة بك أقل من الكمية الدنيا",
                    "One/More vendor's total price in your cart is less than minimum quantity": "إجمالي سعر أحد البائعين/البائعين في عربة التسوق الخاصة بك أقل من الحد الأدنى للكمية",
                    "How to Buy?": "كيف تشتري؟",
                    "Extra Shipping Discount": "خصم إضافي على الشحن",
                    "Extra Discount": "خصم اضافي",
                    "Click Here": "انقر هنا لعرض الوثيقة",
                    "Write Here": "أكتب هنا",
                    "Please select an item": "يرجى تحديد عنصر.",
                    "Removed": "تمت الإزالة",
                    "Payment Details": "تفاصيل الدفع",
                    "Upload Bank Transfer Document": "تحميل إيصال الدفع",
                    "Venderlane saudi made platform": "فندرلين منصة سعودية مرخصة تجمع تجار الجملة مدعومة بحلول تقنية ولوجستية متطورة",
                    "SAR": "ريال",
                    "Add items worth": "أضف منتجات بقيمة",
                    "or more to place the order": "و أكثر لإتمام عملية الطلب",
                    " Please select an item": "اختار منتج",
                    "One/More vendor's total price in your cart is less than minimum order price": "مورد أو أكثر إجمالي المبلغ في سل التسوق أقل من الحد الادنى للطلب",
                    "You’re all set. Let’s Place the order": "تم الوصول للحد الأدنى من الطلب! تمّم الطلب",
                    "Minimum Order": "أقل طلب",
                    "Sold By": "يباع بواسطة",
                    "Browse same vendor products": "تصفح منتجات نفس المورد",
                    "Please enter correct FL number": "الرجاء إدخال رقم الوثيقه",
                    "Please enter correct CR number": "يرجى إدخال رقم السجل التجاري بشكل صحيح",
                    "Prices include added tax": " شامله لقيمه الضريبه المضافه",
                    "venderlane_rights": "جميع الحقوق محفوظة لمنصة فندرلين لتقنية المعلومات",
                    "cr certificate": "مستند السجل التجاري",
                    "Free-Lance Certificate": "مستند وثيقه العمل الحر",
                    "This is not a valid number": "هذا ليس رقم صالح",
                    "shipping discount": "خصم الشحن",
                    "Apply": "فعّل الكود",
                    "Enter coupon code": "أدخل كود الخصم",
                    "Applied": "مُطبَّق",
                    "Remove": "يزيل",
                    "Have a coupon code?": "هل لديك رمز القسيمة؟",
                    "Close Add Address": "إغلاق إضافة عنوان",
                    "No Orders Found": "لاتوجد طلبات سابقه",
                    "Click to open Expanded View": "اضغط للعرض بشكل موسع",
                    "change mobile number": "تغيير رقم الجوال",
                    "update mobile number": "تحديث رقم الهاتف المحمول",
                    "Roll over image to zoom in": 'مرر المؤشر فوق الصورة لتكبيرها',
                    "Bank Method": "حواله بنكيه",
                    "Payment Mode": "طريقة الدفع",
                    "Available Quantity": "الكمية المتاحة",
                    "The available Quantity of Product is": "الكمية المتوفرة من المنتج هي",
                    "Looks like you haven't made any purchases yet": "يبدو أنك لم تقم بأي عملية شراء بعد",
                    "Date of birth cannot be in the future": "لا يمكن أن يكون تاريخ الميلاد في المستقبل",
                    "Seconds": "ثواني",
                    "Resend OTP in": "إعادة إرسال OTP في",
                    "Warehouse Location": "موقع المستودع",
                    "Mobile Number is mandatory": "رقم الجوال مطلوب",
                    "Serving Radius in KM": "المسافه",
                    "Contact Number": "رقم للتواصل",
                    "Contact Mail": "ايميل للتواصل",
                    "Contact Person": "شخص للتواصل",
                    "Code": "رمز",
                    "Add WareHouse": "اضافه مستودع",
                    "Ware Houses": "المستودع",
                    "Ware-House Details": "تفاصيل المستودع",
                    "OTO Details": "تفاصيل OTO",
                    "Name": "الاسم",
                    "Email": "البريد الالكتروني",
                    "Brand Name": "اسم العلامة التجاريه",
                    "City": "المدينة",
                    "Contact Name": "اسم الشخص",
                    "PostCode": "الرمز البريدي",
                    "Input must start with 10": "يجب أن يبدأ الرقم بـ 20 وأن يتراوح طوله بين 10 و15 رقمًا",
                    "Quantity can not be less than minimum quantity": "لا يمكن أن تكون الكمية أقل من الحد الأدنى للكمية",
                    "Track Shippiment": "شحنة المسار",
                    "Track Now": "تأكد الان",
                    "Tracking ID": "رقم تعريف التتبع",
                    "Delivery Status": "حالة التسليم",
                    "Delivery Company": "شركة التوصيل",
                    "freelance license number": "رقم وثيقة العمل الحر",
                    "Freelance": "عمل حر",
                    "Commercial entity": "منشأه تجارية",
                    "Business Type": "نوع النشاط",
                    "english": "إنجليزي",
                    "arabic": "العربية",
                    "return and exchange policy": "سياسة الاسترجاع والاستبدال",
                    "Maximum of 15 number you can use": "الحد الأقصى 15 رقمًا يمكنك استخدامه",
                    "One Time Password": "كلمة المرور مرة واحدة",
                    "Product not available in selected quantity": "إن المنتج غير متوفر بالكمية المحددة",
                    "last year": "العام الماضي",
                    "last 6 month": "آخر 6 أشهر",
                    "last 3 month": "آخر 3 أشهر",
                    "last month": "الشهر الماضي",
                    "Search Order": "بحث",
                    "Update and Save": "تحديث وحفظ",
                    "Total Qty": "إجمالي الكمية",
                    "Total Items": "إجمالي العناصر",
                    "Delivery date": "تاريخ التسليم او الوصول",
                    "Order date": "تاريخ أمر",
                    "Total Amount": "المبلغ الإجمالي",
                    "Globally": "عالميا",
                    "We're Rated": "لقد تم تقييمنا",
                    "Service Provider": "مزودي الخدمة",
                    "Reviews": "مراجعات",
                    "HomePage": "الصفحة الرئيسيه",
                    "No Options": "لا خيارات",
                    "Select Variation": "حدد الأنواع",
                    "Price": "سعر",
                    "Range": "يتراوح",
                    "Variant": "البديل",
                    "Available": "متوفرة",
                    "View more": "عرض المزيد",
                    "View less": "عرض الاقل",
                    "Negative Values are not allowed": "القيم السلبية غير مسموح بها",
                    "Transaction Date": "التاريخ",
                    "Transaction ID": "معرف المعاملة",
                    "Payment Transaction Details": "طريقه الدفع",
                    "IBAN is Not Availabele": "رقم الحساب المصرفي الدولي غير متوفر",
                    "Payment Through": "الدفع من خلال",
                    "To Bank Account": "التحويل المصرفي",
                    "Payment Gateway": "بوابة الدفع",
                    "Your payment is failed": "لقد فشلت عملية الدفع الخاصة بك",
                    "Your payment transaction number": "رقم معاملة الدفع الخاصة بك",
                    "Your payment is Successful": "الدفع الخاص بك ناجح",
                    "back to order detilas": "تفاصيل الطلب الخلفي",
                    "order details": "تفاصيل النظام",
                    "Sorry, the page you are looking for could not be found.": "عذرًا، لا يمكن العثور على الصفحة التي تبحث عنها.",
                    "Page Not Found": "الصفحة غير موجودة",
                    "Minimum 22 digits Required": "الحد الأدنى 22 رقمًا مطلوبًا",
                    "Minimum 4 digits Required": "الحد الأدنى 4 رقمًا مطلوبًا",

                    "IBAN Number is not valid": "رقم الآيبان غير صالح",
                    "I have read and agreed with the Agreement and conditions.": "لقد قرأت ووافقت على الاتفاقية والشروط.",
                    "Agreement and Conditions": "الاتفاقية والشروط",
                    "Please accept the Agreement and Conditions": "يرجى قبول الشروط والأحكام",
                    "Cancel": "رفض",
                    "Accept": "قبول",
                    "IBAN Number": "رقم الحساب المصرفي الدولي ( الايبان )",
                    "products": "منتجات",
                    "productof": "منتجات",
                    "showing": "عرض",
                    "Related blogs": "المدونات ذات الصلة",
                    "All Blogs": "جميع المدونات",
                    "blogs": "المدونات",
                    "information": "عن فندرلين",
                    "all departments": " كل القطاعات ",
                    "cart": "عربة التسوق",
                    "departments": "الأقسام ",
                    "weacceptpaymentmethods": "نحن نقبل جميع طرق الدفع الرئيسية",
                    "directremittance": "نقبل كافة طرق الدفع :",
                    "copyright2": "حقوق النشر@2024 Venderlane.com | كل الحقوق محفوظة",
                    "follow us on social": " تابعونا على منصاتنا الاجتماعية ",
                    "access on the go, download our app": " حمل تطبيقاتنا ",
                    "Become a seller": "سجل كمورد",
                    "SELL ON VENDERLANE": "بيع على فندرلين",
                    "Become a service provider": "كن مزود خدمة",
                    "talk to us": "تكلم معنا",
                    "Rewards": "المكافآت",
                    "Notifications": "إشعارات",
                    "Clearance sale products": "منتجات بيع التخليص",
                    "Discounted, Promo  & combo products": "منتجات العروض الخاصه",
                    "standard products": " المنتجات الأساسية ",
                    "Best seller in": "الأكثر مبيعا في",
                    "notify me": " أخبرني عند التوفر ",
                    "notofied": "أخطرت",
                    "document view": "عرض المستندات",
                    "gallery view": "عرض المعرض",
                    "To Price should not be lesser than From Price": "لا ينبغي أن يكون السعر أقل من السعر",
                    "from": "من",
                    "to": "إلى",
                    "search for city": "البحث عن المدينة",
                    "search for Sub Categories": "البحث عن الفئات الفرعية",
                    "show more": "أظهر المزيد",
                    "show less": "تظهر أقل",
                    "search for brands": "البحث عن العلامات التجارية",
                    "view pdf": "عرض PDF",
                    "doc": "وثيقة",
                    "documents": "وثائق",
                    "locate": "حدد",
                    "Sub Categories": "الفئات الفرعية",
                    "& Higher": "& فأعلى",
                    "Customer Reviews": "مراجعات العملاء",
                    "most viewed": "الأكثر عرضًا",
                    "brands": "ماركة",
                    "clear filters": "محو الكل",
                    "star": "نجم",
                    "out of 5": "من أصل 5",
                    "customer review": "مراجعات العملاء",
                    "global ratings": "تقييمات عالمية",
                    "Categories": "فئات",
                    "Variations": "الاختلافات",
                    "in": "في",
                    "in SAR": "في",
                    "apply filters": " تطبيق التخصيص ",
                    "price range": "نطاق السعر",
                    "most ordered": "الأكثر طلبًا",
                    "stock available": "مخزون متاح",
                    "most rated": "الأكثر تقييما",
                    "No Ratings Yet": "لا يوجد تقييم",
                    "download file": "تحميل الملف",
                    "Uploading Document": "تحميل الوثيقة",
                    "tax invoice": "الفاتوره المبدئيه",
                    "enter your phone number start with 5xxxxxxxx": "أدخل رقم هاتفك المحمول مبتدئًا بالرقم 5",
                    "not include decimal": "يجب ألا تتضمن الكمية علامة عشرية",
                    "more than zero": "يجب ألا تكون الكمية صفراً",
                    "service questions": "سؤال يتعلق بمزود الخدمة",
                    "Rating and Review": "التقييمات والمراجعات",
                    "no reviews yet": "لا توجد تعليقات حتى الآن",
                    "per pieces": "للقطعه الواحدة",
                    "pieces": "قِطَع",
                    "rate vendor": "قيم البائع",
                    "enter comments": "أدخل التعليقات",
                    "submit feedback": "إرسال الملاحظات",
                    "rate us": "قيمنا",
                    "rate yor experience": "قيم تجربتك",
                    "inquiry and support": "للمساعدة والدعم التواصل مع",
                    "request underprocess": "طلب التسجيل الخاص بك يجري التحقق منه، سيتم الرد عليك قريبا",
                    "select delivery address": "حدد عنوان التسليم",
                    "confirm and submit": "تأكيد وإرسال الطلب",
                    "items not found": "لم يتم العثور على العناصر",
                    "menu": "القائمه",
                    "register": "يسجل",
                    "need shipping": "الرجاء تحديد الشحن مطلوب أم لا.",
                    "do you need shipping": "هل تحتاج للشحن ؟",
                    "shipping charge": "رسوم الشحن",
                    "forgot password": "هل نسيت كلمة السر",
                    "add address": "إضافة عنوان جديد",
                    "no addresses": "لا توجد عناوين محفوظة",
                    "Continue shoping": "مواصلة التسوق",
                    "you have not ordered anything": "ماوصلنا منك طلب!",
                    "loading": "تحميل......",
                    "vender privacy": "سياسة خصوصية VenderLane",
                    "terms sub heading": "الشروط والأحكام العامة التي تحكم خدمات Vender Lane للكيانات التجارية ورواد الأعمال.",
                    "vterms&condition": "شروط وأحكام VenderLane",
                    "faqs": "أسئلة مكررة",
                    "estimated": "مُقدَّر",
                    "reject order": "رفض الطلب",
                    "faq": "أسئلة مكررة",
                    "vender information": " تسجيل البيانات ",
                    "copyright": "فندر لين | حقوق الطبع والنشر © 2023، جميع الحقوق محفوظة | سياسة الخصوصية",
                    "select services": "اختر الخدمات",
                    "get directions": "احصل على الاتجاهات",
                    "service provider registration": "تسجيل مقدم الخدمة",
                    "Please_Select_address": "يرجى اختيار العنوان",
                    "select item quantity": "حدد كمية العنصر",
                    "open": "يفتح",
                    "closed": "مغلق",
                    "closses": "مغلق",
                    "opens": "مفتوح",
                    "contact mail address": " يرجى ادخال البريد الالكتروني",
                    "service providers": "مقدمي الخدمة",
                    "select service provider": "حدد مزودي الخدمة",
                    "select category": "اختر الفئة",
                    "serivice provider": "الخدمات الأخرى",
                    "go home": "العوده للصفحة الرئيسيه",
                    "business documents": "وثائق العمل",
                    "Directions": "الاتجاهات",
                    "serivece categories": "فئات الخدمة",
                    "Working Hours": "ساعات العمل",
                    "service provider information": "معلومات مقدم الخدمة",
                    "city": "مدينة",
                    "address": "عنوان",
                    "other services": "خدمات أخرى",
                    "business name": " الاسم التجاري",
                    "business city": "مدينة الأعمال",
                    "business address": "عنوان العمل",
                    "business category": "نوع العمل",
                    "service provider list": "قائمة مزودي الخدمة",
                    "Cities": "مدن",
                    "categories": "فئات",
                    "download our app": "حمل التطبيق الآن",
                    "get the app": "احصل على التطبيق",
                    "download venderlane app": "حمل تطبيق فندر لين",
                    "two million products twenty cat": "احصل على فرصة الوصول المباشر والفوري للموردين والبضائع",
                    "top brands and vendors": "إبدأ رحلتك مع منصة تجميع الأعمال الآن",
                    "view all": "عرض الكل",
                    "about us": "من نحن",
                    "about vender lane": "فريقنا",
                    "gift cards": "عن فندر لين",
                    "careers": "الوظائف",
                    "sell on venderlane": "البيع على فندر لين",
                    "helpdesk": "مكتب المساعدة",
                    "investor relations": "علاقات المستثمرين",
                    "advertise with us": "الإعلان معنا",
                    "locations": "المواقع",
                    "customer service": "خدمة العملاء",
                    "my orders": "طلباتي",
                    "my account": "حسابي",
                    "track my orders": "تتبع طلباتي",
                    "accessibility statement": "بيان إمكانية الوصول",
                    "return policy": "سياسة الإرجاع",
                    "help center": "مركز المساعدة",
                    "contac us": "اتصل بنا",
                    "quick help": "تحدث معنا",
                    "call us": "اتصل بنا",
                    "privacy policy": "سياسة الخصوصية",
                    "shopping assistance": "مساعدة التسوق",
                    "app title": "فندر لين",
                    "languages": "اللغات",
                    "sell on": "البيع على فندرلين",
                    "Sign in/Sign up": "تسجيل الدخول / التسجيل",
                    "login with otp": "تسجيل الدخول باستخدام رمز التحقق",
                    "Login with password": " تسجيل الدخول باستخدام كلمة المرور",
                    "Please enter the password": "  يرجى إدخال كلمة المرور",
                    "Please enter the mobile number": "يرجى إدخال رقم الهاتف المحمول",
                    "Please enter the valid mobile number": " يرجى إدخال رقم هاتف محمول صالح",
                    "mobile number is required": "رقم الجوال مطلوب*",
                    "Password is Required": "كلمة المرور مطلوبة*",
                    "must contain": "يجب أن تحتوي على 8 خانات على الأقل، حرف كبير واحد، حرف صغير واحد، رقم واحد وحرف خاص واحد",
                    "send otp": "إرسال رمز التحقق (OTP)",
                    "minimum number": "يجب أن يحتوي على 9 أرقام على الأقل",
                    "verify otp": "التأكد من رمز التحقق ",
                    "login": "تسجيل الدخول",
                    "dont have account": "ليس لديك حساب؟",
                    "create new account": "إنشاء حساب جديد",
                    "register with us": "التسجيل معنا",
                    "mobile number": "رقم الهاتف المحمول",
                    "first name": "الاسم الأول",
                    "last name": "الاسم الأخير",
                    "email": "عنوان البريد الالكتروني",
                    "cr number": "رقم السجل التجاري",
                    "company name": "اسم الشركه/المؤسسه",
                    "password": "كلمة المرور",
                    "dob": "تاريخ الميلاد (اختياري)",
                    "confirm password": " تأكيد كلمة المرور",
                    "submit": "إرسال",
                    "already have account": "لديك حساب قائم",
                    "login here": "تسجيل الدخول هنا",
                    "Please enter the first name": "يرجى إدخال الاسم الأول",
                    "Please enter the last name": "يرجى إدخال الاسم الأخير",
                    "Please enter the email id": "يرجى إدخال عنوان البريد الإلكتروني",
                    "Please enter the confirm password": "يرجى إدخال تأكيد كلمة المرور",
                    "Password not matched": "كلمة المرور غير متطابقة",
                    "Please enter the valid email": "يرجى إدخال عنوان بريد إلكتروني صحيح",
                    "last name is required": "الاسم الأخير مطلوب*",
                    "cr number is required": "رقم السجل التجاري مطلوب*",
                    "company name is required": "اسم الشركة مطلوب*",
                    "password is required": "كلمة المرور مطلوبة*",
                    "first name is required": "الاسم الأول مطلوب*",
                    "mail is required": "البريد الإلكتروني مطلوب*",
                    "department is required": "القسم مطلوب*",
                    "confirm passowrd is required": "تأكيد كلمة المرور مطلوب*",
                    "phone number is not valid": "رقم الهاتف غير صالح",
                    "enter a Valid mail address": "أدخل عنوان بريد صالح",
                    "12 digits cr": "الحد الأدنى للأرقام هو 12 رقمًا ",
                    "invalid CR number": "رقم السجل التجاري غير صالح",
                    "passwords are not matching": "كلمة المرور وتأكيد كلمة المرور غير متطابقتين",
                    "current Password is Required": "كلمة المرور الحالية مطلوبة",
                    "new Password is Required": "كلمة المرور الجديدة مطلوبة",
                    "current password": "كلمة المرور الحالية",
                    "new Password": "كلمة المرور الجديدة",
                    "update password": "تحديث كلمة المرور",
                    "enter current password": "الرجاء إدخال كلمة المرور الحالية",
                    "enter new password": "الرجاء إدخال كلمة المرور الجديدة",
                    "otp require": "رمز التحقق مطلوب*",
                    "minimum otp": "يجب أن يكون هناك 6 أرقام كحد أدنى",
                    "max num": "بحد أقصى 10 أرقام يمكنك استخدامها",
                    "max num cr": "بحد أقصى 15 أرقام يمكنك استخدامها",
                    "max num_fl": "بحد أقصى 15 أرقام يمكنك استخدامها",

                    "we are available on ios and android": "نحن متوفرون على نظامي التشغيل iOS و Android",
                    "add to cart": "إضف إلى السلة",
                    "out of stock": "نفذ المخزون",
                    "grab offers": "استفد من العروض",
                    "department": "القسم",
                    "select department": "اختر الأقسام",
                    "results not found": "لم يتم العثور على نتائج",
                    "account": "الحساب",
                    "chats": "الدردشة",
                    "wishlist": "قائمة الرغبات",
                    "search": "البحث في فندرلين",
                    "top products of the day": "أفضل المنتجات اليوم",
                    "Explore other departments": "استكشف الأقسام الأخرى",
                    "sort": "ترتيب حسب",
                    "filters": " تخصيص بالنسبه الى ",
                    "new": "الأحدث أولاً",
                    "old": "الأقدم أولا",
                    "hightolow": "الأعلى سعرًا",
                    "lowtohigh": "الأقل سعرًا",
                    "listings": "القائمة",
                    "update cart": "تحديث السلة",
                    "add to wishlist": " إضافة إلى قائمة الرغبات",
                    "remove from WishList": "إزالة من قائمة الرغبات ",
                    "product details": "تفاصيل المنتج",
                    "similar products": "منتجات مماثلة",
                    "minimum order quantity": "الحد الأدنى للطلب",
                    "select quantity": "حدد الكمية",
                    "add item": "إضافة منتجات",
                    "extra": "إضافي",
                    "off": "خصم",
                    "This field is required": "هذا الحقل مطلوب",

                    "hello": "مرحبًا",
                    "orders": "الطلبات",
                    "all orders": "جميع الطلبات",
                    "orders chat": "محادثة الطلبات",
                    "my profile": "ملفي الشخصي",
                    "saved address": " العناوين المحفوظه ",
                    "legal": "قانوني",
                    "terms & conditions": "الشروط والأحكام",
                    "logout": "تسجيل الخروج",
                    "are you sure": "هل أنت متأكد؟",
                    "no": "لا",
                    "yes": "نعم",
                    "logout from venderlane": "تسجيل الخروج من حساب فندر لين",
                    "You want to Delete the address!": "هل تريد حذف العنوان؟",
                    "order id": "رقم الطلب",
                    "total price": "السعر الإجمالي",
                    "order status": "حالة الطلب",
                    "quatation from venderlane": "عرض أسعار من فندرلين",
                    "items in this order": "المنتجات في هذا الطلب",
                    "delivery address": "عنوان التسليم",
                    "total order price": "إجمالي سعر الطلب",
                    "summary": "الملخص",
                    "subtotal": "الإجمالي الفرعي:",
                    "vat 18%": "ضريبة القيمة المضافة",
                    "discount": "الخصم",
                    "total": "الإجمالي",
                    "chat with vender": "محادثة مع المورد",
                    "enter your message": "أدخل رسالتك",
                    "send": "إرسال",
                    "you have": "لديك",
                    "new message from Vendor": "رسالة جديدة من المورد",
                    "edit profile": "تعديل الملف الشخصي",
                    "registred mobile number": "رقم الهاتف المحمول المسجل",
                    "change password": "تغيير كلمة المرور ",
                    "update": "تحديث",
                    "update user details": "تحديث تفاصيل المستخدم",
                    "add new address": "إضافة عنوان جديد",
                    "edit": " تعديل",
                    "delete": "حذف",
                    "shop by category": "تسوق حسب الاقسام",
                    "Make this address as default address": "اجعل هذا العنوان هو العنوان الافتراضي",
                    "landmark": "العلامة المميزة (اختياري)",
                    "pincode": "الرمز البريدي",
                    "pincode minimum": "يجب أن يحتوي على 5 أرقام على الأقل*",
                    "pincode is required": "الرمز البريدي مطلوب*",
                    "add": "إضافة",
                    "the address": "العنوان",
                    "remove item": "إزالة المنتج",
                    "move to wishlist": "انتقل إلى قائمة الرغبات",
                    "items": "المنتجات",
                    "my cart": "سلتي",
                    "total quantity count": "إجمالي عدد القطع",
                    "total est price": "إجمالي قيمة المنتجات",
                    "request for quotation": "طلب عرض اسعار",
                    "submit order": "تقديم الطلب",
                    "address is required": "العنوان مطلوب*",
                    "search address": "البحث عن عنوان",
                    "order request submitted": "تم استلام طلبكم بنجاح",
                    "view orderlist": "عرض قائمة الطلبات",
                    "move to cart": "انتقل إلى السلة",
                    "cart empty": "سلة التسوق فارغة...",
                    "continue shopping": "متابعة التسوق",
                    "empty wishlist": "قائمة الرغبات فارغة...",
                    "Minimum 10 digits Required": "الحد الأدنى 10 أرقام مطلوبة",
                    "Minimum 8 digits Required": "الحد الأدنى 8 أرقام مطلوبة",
                    "details are not availanble": "التفاصيل غير متوفرة...",
                    "data is not there": "التفاصيل غير متوفرة عن القسم المحدد",
                    "select other dept": "اختر قسمًا آخر من الأعلى",
                    "combo offer deals": "المجموعات والعروض والصفقات",
                    "Flat 10% of for new user": "خصم 10% على المبلغ الإجمالي للفواتير للمستخدمين الجدد. استخدم الرمز - NEWONVLANE",
                    "newonvlane": "جديد على فندرلين",
                    "clearance sale": "مبيعات التصفيه",
                    "Quantity should not less than minimum quantity": "الكمية لا يجب أن تكون أقل من الحد الأدنى المطلوب",
                    "my chats": "محادثاتي",
                    "vendor business listing": "قائمة أعمال المورد",
                    "seller business listing": "قائمة الأعمال البائع",
                    "tax certificate": "شهادة الضريبة",
                    "add website linr here (optional)": "أضف رابط الموقع هنا (اختياري)",
                    "Listing Information": "معلومات القائمة",
                    "Business Location": "موقع العمل",
                    "Building": "مبنى",
                    "Street": "شارع",
                    "Area": "منطقة",
                    "State": "ولاية",
                    "Country": "دولة",
                    "landmarkTwo": "Landmark",
                    "Legal business name": "الاسم التجاري القانوني",
                    "Business category": "نوع العملy",
                    "Business Information": "معلومات العمل",
                    "Business Sub Cateories": "فئات الأعمال الفرعية",
                    "Business Cateories": "فئات الأعمال",
                    "Business Description": "وصف العمل",
                    "Business Timing": "توقيت العمل",
                    "Sun": "الأحد",
                    "Mon": "الاثنين",
                    "Tue": "يوم الثلاثاء",
                    "Wed": "الأربعاء",
                    "Thu": "يوم الخميس",
                    "Fri": "جمعة",
                    "Sat": "السبت",
                    "open by": "من ",
                    "close by": "إلى",
                    "Upload Cover Photo": "تحميل صورة الغلاف",
                    "Upload Profile Photo": "تحميل صورة الملف الشخصي",
                    "Upload Photo": "تحميل الصورة",
                    "manager name": "اسم المدير",
                    "business mail address": "البريد الالكتروني للمنشأة",
                    "business contact number": "رقم تلفون المنشأه ",
                    "Upload Product Photo": "تحميل صورة المنتج",
                    "landline number": "رقم الهاتف الثابت",
                    "webisite": "الموقع الإلكتروني",
                    "stores": "المتاجر",
                    "accept order": "قبول الطلب",
                    "chat box": "صندوق المحادثة",
                    "Business Name is Required*": "اسم المنشأه التجاري مطلوب*",
                    "Manager Name is Required*": "اسم المدير مطلوب*",
                    "Business mail is Required*": "البريد الإلكتروني للعمل مطلوب*",
                    "City is Required*": "أسم المدينة مطلوب*",
                    "Category is Required*": "الفئة مطلوبة*",
                    "Description is Required*": "الوصف مطلوب*",
                    "Business days are Required*": "أيام العمل مطلوبة*",
                    "Required*": "مطلوب*",
                    "Landline Number is Required*": "رقم الهاتف الثابت مطلوب*",
                    "Tax file is Required*": "الملف الضريبي مطلوب*",
                    "CR file is Required*": "السجل التجاري مطلوب*",
                    "listing information": "معلومات القائمة",
                    "product categories": "فئات المنتجات",
                    "Descriptions": "الوصف",
                    "photos": "الصور",
                    "select city": "اختر مدينة",
                    "something went wrong": "عذرًا ، حدث خطأ",
                    "data not available": "البيانات التي تبحث عنها غير متوفرة",
                    "go back": "العودة",
                    "back": "خلف",
                    "depart_ment": "قسم",
                    "Seller Registration": "تسجيل البائع",
                    "upload business logo": "تحميل شعار الشركة",
                    "Other service registration": "تسجيل خدمة أخرى",
                    "step two": "الخطوة الثانية",
                    "upload business photos": "تحميل صور الأعمال",
                    "register as a seller": "سجل كمورد",
                    "register as a buyer": "سجل كمشتري",
                    "register as a service provider": "سجل كمزود خدمة",
                    "our team": "فريقنا",
                    "our story": "قصتنا",


                },
            },
        },
        fallbackLng: "ar",
        detection: {
            order: ["path", "localStorage", "htmlTag", "cookie"],
            caches: ["localStorage", "cookie"],
        },
    });

export default i18n;